.create-edit-pwa > div:not(.modal-wnd-background) {
    margin-top: 12px;
}

.create-edit-pwa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0 !important;
}

.create-edit-pwa-header .go-to-back-btn > span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.create-edit-pwa-header .go-to-back-btn svg {
    width: 20px;
    height: auto;
    display: flex;
}

.create-edit-pwa-header .go-to-back-btn svg path {
    stroke: var(--text-color-warning-btn);
}

.create-edit-pwa-header > div {
    width: 203.3px;
}

@media (max-width: 630px) {
    .create-edit-pwa-header {
        flex-direction: column-reverse;
        gap: 10px;
    }
    .create-edit-pwa-header > div {
        display: none;
    }
}

.create-edit-pwa label {
    font-size: 14px;
    padding-bottom: 4px;
    display: block;
}

.create-edit-pwa .filed-block {
    margin-top: 12px;
}

.add-language-form h2 {
    margin: 0;
    font-size: 24px;
    text-align: center;
}

.create-edit-pwa .select.small * {
    font-size: 13px;
}

.create-edit-pwa .four-fields {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    margin-top: 12px;
}

.create-edit-pwa .four-fields .filed-block {
    margin: 0;
}

.create-edit-pwa .four-fields input:not(.create-edit-pwa .form-check-input) {
    width: 100%;
}

.create-edit-pwa textarea {
    width: 100% !important;
    min-height: 60px;
}

.create-edit-pwa .label-with-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    row-gap: 0px;
    flex-wrap: wrap;
}

.create-edit-pwa .label-with-checkbox label {
    padding: 0px 0 3px;
}

.create-edit-pwa input[type="number"] {
    height: 32px;
    padding: 6px;
    font-size: 16px;
}

.create-edit-pwa textarea {
    padding: 6px;
    font-size: 14px;
}

.create-edit-pwa .small .css-13cymwt-control,
.create-edit-pwa .small .css-t3ipsp-control {
    min-height: 32px;
    min-height: auto;
}

.create-edit-pwa .small .css-1xc3v61-indicatorContainer,
.create-edit-pwa .small .css-15lsz6c-indicatorContainer {
    padding: 5px;
}

@media (max-width: 1023px) {
    .create-edit-pwa .four-fields {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 500px) {
    .create-edit-pwa .four-fields {
        grid-template-columns: 1fr;
    }
}

.create-edit-pwa .img-block {
    width: 100px;
    height: 100px;
    padding: 8px;
    background-color: var(--bg-color-input);
    border-radius: 6px;
    position: relative;
}

.create-edit-pwa .img-block > div {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    transition: transform 0.3s ease-in;
    position: relative;
}

.create-edit-pwa .img-block button {
    box-sizing: content-box;
    background-color: var(--bg-color-card);
    width: 18px;
    height: 18px;
    padding: 6px;
    border: 0;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
    box-shadow: var(--box-shadow-card);
    transition: all 0.23s ease 0.1s;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-edit-pwa .img-block .seeBtn {
    top: -12px;
    right: 19px;
}

.create-edit-pwa .img-block button svg path {
    fill: var(--fill-icons);
    transition: all 0.23s ease 0.1s;
}

.create-edit-pwa .img-block .closeBtn {
    top: -12px;
    right: -12px;
}

.create-edit-pwa .img-block button:hover {
    transform: scale(1.15);
}
.create-edit-pwa .img-block button:hover svg path {
    fill: var(--fill-icons-hover);
}

.create-edit-pwa .screens-block {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.create-edit-pwa .footer-block {
    display: flex;
    justify-content: center;
    position: relative;
}

.create-edit-pwa .footer-block .error-block {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    top: 110%;
    font-size: 11px;
    font-weight: 500;
}

.create-edit-pwa .desc-lang {
    border: 1px solid var(--border-color-tabs);
    border-radius: 0px 6px 6px 6px;
    padding: 0 16px 16px;
}

.create-edit-pwa .static-desc {
    margin-top: 32px;
}

.create-edit-pwa .news-block,
.create-edit-pwa .window-settings-block {
    grid-template-columns: 1fr 2fr 1fr;
}

.create-edit-pwa .tags-block {
    grid-template-columns: 2fr 2fr;
}

.create-edit-pwa .clue-icon-wrapper {
    padding: 0 5px;

    cursor: pointer;
}

.create-edit-pwa .clue-icon-wrapper svg {
    margin-bottom: -4px;
    width: 18px;
    height: auto;
}

.create-edit-pwa .clue-icon-wrapper svg path,
.create-edit-pwa .clue-icon-wrapper svg {
    fill: var(--fill-icons);
    transition: all 0.3s ease-in;
}

.create-edit-pwa .clue-icon-wrapper:hover svg path,
.create-edit-pwa .clue-icon-wrapper svg {
    fill: var(--fill-icons-hover);
}

@media (max-width: 767px) {
    .create-edit-pwa .news-block,
    .create-edit-pwa .window-settings-block {
        grid-template-columns: 1fr;
    }

    .create-edit-pwa .tags-block {
        grid-template-columns: 1fr;
    }
}

.create-edit-pwa .error-state {
    position: relative;
}

.create-edit-pwa .error-msg {
    color: var(--error-color);
    position: absolute;
    left: 0;
    top: 100%;
    display: none;
}

.create-edit-pwa .error-state .error-msg {
    display: block;
}

#root .create-edit-pwa .error-state input,
#root .create-edit-pwa .error-state .textarea-wrapper {
    border-color: var(--error-color) !important;
}

/* COMMENTS */

.create-edit-pwa .comments-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid var(--border-color-tabs);
    padding: 16px;
    border-radius: 0 6px 6px;
}

.create-edit-pwa .comment {
    border-radius: 12px;
    border: 1px solid var(--border-color-input);

    display: grid;
    grid-template-columns: 1.5fr 1fr;
}

.create-edit-pwa .comment > div {
    padding: 16px;
}

.create-edit-pwa .comment .up-block {
    display: grid;
    grid-template-columns: 100px 1fr;
    gap: 25px;
    align-items: center;
}

.create-edit-pwa .comment .up-block .filed-block:first-child {
    margin-top: 0;
}

.create-edit-pwa .comment .answer-block {
    border-left: 1px solid var(--border-color-input);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
}

.create-edit-pwa .comment .answer-block .filed-block {
    margin-top: 0;
}

.create-edit-pwa .comment .answer-block .actions-block {
    display: flex;
    gap: 16px;
    justify-content: end;
}

.create-edit-pwa .comment .answer-block .loader {
    margin: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
    .create-edit-pwa .comment .up-block {
        grid-template-columns: 1fr;
        gap: 16px;
    }
}

@media (max-width: 767px) {
    .create-edit-pwa .comment {
        grid-template-columns: 1fr;
    }
    .create-edit-pwa .comment .answer-block {
        border-top: 1px solid var(--border-color-input);
        border-left: 0;
    }
}

.comments-pwa .two-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
}

.comments-pwa .two-fields input {
    width: 100%;
}

.comments-pwa .two-fields .filed-block {
    margin: 0;
}

.comments-pwa .buttons-block {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

@media (max-width: 390px) {
    .comments-pwa .two-fields {
        grid-template-columns: 1fr;
    }
}

/* SETTING */

.settings-pwa > div:first-child {
    margin-top: 0;
}

.settings-pwa .redirect-desc {
    margin-top: 3px;
    font-size: 12px;
}

.settings-pwa .redirect-desc * {
    font-size: 12px;
    margin: 0;
}

/* CALENDAR */
.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker-wrapper .calendar-icon {
    width: 22px;
    height: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
    pointer-events: none;
}

.react-datepicker-wrapper .calendar-icon path {
    stroke: var(--fill-icons);
}

.react-datepicker-wrapper input {
    padding-left: 35px !important;
}

.react-datepicker {
    border-color: var(--border-color-input);
    font-size: 10px;
}

.react-datepicker__header {
    border-color: var(--border-color-input);
    background-color: var(--bg-color-input);
}

.react-datepicker__current-month {
    color: var(--primary-text-color);
    font-size: 12px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    margin: 5px;
}

.react-datepicker__month-container {
    background-color: var(--bg-body);
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: var(--primary-text-color);
}

.react-datepicker__day--selected {
    color: #fff;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    fill: var(--bg-color-input);
    color: transparent;
    stroke: var(--border-color-input);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    color: var(--fill-icons);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background: var(--bg-color-card);
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
    width: 6px;
    height: 6px;
}

button.react-datepicker__navigation--next {
    padding-right: 0 !important;
}

.react-datepicker-time__header, .react-datepicker__time-list-item  {
    color: var(--primary-text-color);
}

.react-datepicker__time-container .react-datepicker__time {
    background-color: var(--bg-color-input);
}

.react-datepicker {
    background-color: var(--bg-body);
}

.react-datepicker__time-container {
    border-color: var(--border-color-input);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    background: var(--bg-color-card);
}

.react-datepicker__time-list .react-datepicker__time-list-item  {
    height: auto !important;
}

.react-datepicker__day--keyboard-selected {
    background: var(--bg-color-card);
}

.react-datepicker__day--disabled {
    color: #999999;
    cursor: default;
}

.react-datepicker__day--disabled:hover {
    background: none;
}
