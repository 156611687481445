.edit-new-client form ul li.field > div:first-child {
    width: 220px;
}

.create-new-client form ul li.field > div:first-child {
    width: 200px;
}
.create-new-client form ul > li.field > div:last-child {
    width: 260px;
}

.create-new-client form,
.edit-new-client form,
.manage-games .settings-form,
.deposit-funds-admin form {
    width: 100%;
    background: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: 0;
}

.create-new-client form input:not(.select input),
.edit-new-client form input,
.manage-games
    .settings-form
    input:not(
        .manage-games .settings-form input[type="checkbox"],
        .select input
    ),
.server-setting input:not(.select input),
.deposit-funds-admin form input {
    background: var(--bg-color-input);
    border: 1px solid var(--border-color-input) !important;
    border-radius: 6px !important;
    color: var(--primary-text-color);
}

.manage-games
    .settings-form
    input:not(.manage-games .settings-form input[type="checkbox"]),
.deposit-funds-admin form input {
    width: 100%;
}

.manage-games .settings-form ul li.field > div:first-child,
.deposit-funds-admin form ul li.field > div:first-child {
    width: 220px;
}

@media (min-width: 560px) {
    .manage-games .checkbox-row .inRow {
        width: 256px;
        justify-content: start;
    }
}

.deposit-funds-admin form ul li.field > div:first-child {
    font-size: 15px;
}

.deposit-funds-admin form h2 {
    margin: 0;
    text-align: center;
}

.edit-new-client h2 {
    margin: 5px 0 25px 40px;
}

.edit-new-client-header {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
}

.edit-new-client-header .select {
    max-width: 500px;
    width: 100%;
}

.edit-new-client-header > div:first-child,
.edit-new-client-header > div:last-child {
    width: 153px;
}

@media (max-width: 900px) {
    .edit-new-client-header {
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .edit-new-client-header > div:first-child button {
        margin-bottom: 16px;
    }

    .edit-new-client-header > div:last-child {
        display: none;
    }
}

@media (max-width: 560px) {
    .create-new-client form .field,
    .edit-new-client form .field,
    .manage-games .settings-form .field,
    .deposit-funds-admin form .field {
        flex-direction: column;
    }

    .create-new-client form .field > div:first-child,
    .edit-new-client form .field > div:first-child,
    .manage-games .settings-form .field > div:first-child,
    .deposit-funds-admin form .field > div:first-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 0 10px !important;
    }

    .create-new-client .field > div:first-child br,
    .edit-new-client .field > div:first-child br,
    .manage-games .settings-form .field > div:first-child br,
    .deposit-funds-admin form .field > div:first-child br {
        display: none;
    }

    .create-new-client .field > div:last-child,
    .edit-new-client .field > div:last-child,
    .manage-games .settings-form .field > div:last-child,
    .deposit-funds-admin form .field > div:last-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }

    .edit-new-client h2 {
        margin: 0;
        text-align: center;
    }

    .manage-games .settings-form > ul > li {
        align-items: start;
    }
}

.deposit-funds h2 {
    margin: 5px 0 20px 20px;
}

.deposit-funds .wallet-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 13px;
    max-width: 757px;
    margin: 0 auto 16px;
}

.deposit-funds .wallet-info b {
    font-size: 15px;
}

.deposit-funds .soft {
    font-size: 11px;
}

.deposit-funds table tr td:nth-child(2) {
    text-align: center;
}

.deposit-funds .wallet-info button {
    top: -2px;
    left: -3px;
}

.deposit-funds .wallet-info > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media (max-width: 550px) {
    .deposit-funds .wallet-info {
        width: auto;
        margin: 0 0 16px;
    }

    .deposit-funds .wallet-info > div:first-child {
        flex-direction: column;
        align-items: start;
    }

    .deposit-funds .wallet-info > div:first-child .readOnlyFrame {
        max-width: max-content;
        width: 85vw;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}

.deposit-funds .wallet-info .readOnlyFrame {
    background: var(--bg-color-payment-processing);
    border-color: var(--border-color-payment-processing);
    padding: 4px 8px 3px;
    color: var(--primary-text-color);
}

.deposit-funds form ul > li.field > div:first-child {
    width: 190px;
    padding-left: 0px !important;
}
.deposit-funds form ul > li.field > div:last-child {
    width: 200px;
}
.deposit-funds form ul > li.submit {
    margin-top: 20px !important;
}

.manage-games .form {
    width: 100%;
    max-width: 500px;
}
.manage-games .card.thin {
    margin: 0 5px 15px;
}

.manage-games .card.thin form {
    max-width: none;
    background: none;
    padding: 0;
    border: none;
    margin: 0;
}

.manage-games form.thin ul > li.field > div:first-child {
    width: 160px;
}
.manage-games form.thin ul > li.field > div:last-child {
    width: 370px;
}
.manage-games form.thin ul > li.inCol > div.inRow {
    width: 270px;
    justify-content: start;
}

.manage-games .checkbox-item {
    display: flex;
    align-items: center;
}

.admin {
    color: #ece498 !important;
}

.listSmallButton {
    display: inline-block;
    position: relative;
    background: none;
    width: 14px;
    height: 9px;
    padding: 0;
    border: none;
}
.listSmallButton > .small2Icon {
    position: relative;
    vertical-align: baseline;
    opacity: 0.5;
    left: 5px;
}
.listSmallButton:hover > .small2Icon {
    opacity: 1;
}

.gameReleaseStatus {
    display: flex;
    flex-wrap: wrap;
}

.gameReleaseStatus > div {
    display: inline-block;
    width: 110px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid;
    opacity: 0.5;
    background-color: #a9bdce;
    color: #000;
    margin: 4px 6px;
    font-size: 11px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    padding: 0 4px;
    box-sizing: content-box;
}
.gameReleaseStatus > div.active {
    cursor: default;
    border-width: 3px;
    font-weight: bold;
    opacity: 1;
}

.gameReleaseStatus > div.grs-nr {
    background-color: #b7c8ec;
    border-color: #7b7baf;
}
.gameReleaseStatus > div.grs-w {
    background-color: #c9b7ec;
    border-color: #967baf;
}
.gameReleaseStatus > div.grs-normal {
    background-color: #63e872;
    border-color: #3d8e46;
}
.gameReleaseStatus > div.grs-dev {
    background-color: #9fe7a7;
    border-color: #4d8352;
}
.gameReleaseStatus > div.grs-fb99 {
    background-color: #e8c263;
    border-color: #786433;
}
.gameReleaseStatus > div.grs-fbban {
    background-color: #cecece;
    border-color: #5e5e5e;
}
.gameReleaseStatus > div.grs-ban {
    background-color: #e86363;
    border-color: #783333;
}

.server-setting .form {
    width: 100%;
    max-width: 500px;
}

.server-setting .form button {
    width: auto;
}

.server-setting .readOnlyFrame {
    background: var(--bg-color-card);
    box-shadow: var(--box-shadow-card);
    border: none;
    margin-top: 10px;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
}

.server-setting .domain-func {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 15px;
}
