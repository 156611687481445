.languages-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 4px;
}

.languages-tab {
    padding: 8px 16px;
    cursor: pointer;
    background-color: var(--bg-body);
    border: 1px solid var(--border-color-tabs);
    /* margin-right: 2px; */
    position: relative;
    border-radius: 6px 6px 0 0;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease-in-out;
}

.without-border-b .languages-tab {
    border-bottom: none;
}

.languages-tab.active {
    background-color: var(--bg-color-card);
    text-shadow: 0 0 0.25px currentcolor;
    color: #1890ff;
}

.languages-tab::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: transparent;
}

.languages-tab.active::after {
    background-color: var(--bg-color-card); /* Цвет подчеркивания */
}

.languages-tabs .close-btn {
    background: none;
    border: none;
    padding: 0;
    margin-left: 8px;
    cursor: pointer;
}

.languages-tabs .close-btn svg {
    width: 18px;
    height: auto;
    display: block;
}

.languages-tabs .close-btn svg path,
.languages-tab.add-tab svg path {
    fill: var(--fill-icons);
    transition: fill 0.3s ease-in;
}

.languages-tabs .custom-btn > span svg path {
    fill: var(--text-color-warning-btn);
}

.light .languages-tabs .custom-btn > span svg path {
    fill: #ef7500;
}

.languages-tabs .close-btn:hover svg path,
.languages-tab.add-tab:hover svg path {
    fill: var(--fill-icons-hover);
}

.languages-tab.add-tab {
    cursor: pointer;
    color: white;
    padding: 4px 8px;
    margin-top: 1px;
}

.languages-tab.add-tab span {
    padding-left: 4px;
    color: var(--primary-text-color);
    display: none;
}

.without-langs .languages-tab.add-tab {
    border-radius: 6px;
}

.without-langs .languages-tab.add-tab span {
    display: inline;
}

.languages-tab.add-tab svg {
    transform: rotate(45deg);
    width: 24px;
    height: auto;
    display: block;
}

.languages-tab.add-tab:disabled {
    opacity: 0.6;
    cursor: default;
}

.languages-tab.add-tab:disabled svg path {
    fill: var(--fill-icons) !important;
}

.languages-tab-content {
    padding: 16px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    height: 100px;
}

.languages-tabs .custom-btn > span {
    display: flex;
    align-items: center;
    gap: 5px;
}

.languages-tabs .custom-btn > span svg {
    transform: rotate(45deg);
    width: 20px;
    height: auto;
    display: block;
}

.light .languages-tabs .custom-btn {
    /* animation: scaleAnimation 1s infinite ease-in-out; */
    background-color: #fffbf8;
    border-color: #ef7500;
    color: #ef7500;
}

/* .languages-tabs .custom-btn > span svg {
    animation: scaleAnimation 1s infinite ease-in-out;
} */
