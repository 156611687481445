body {
    --font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;

    --bg-body: #25293c;

    --primary-text-color: #e4e8ff;
    --secondary-text-color: #ff9f43;
    --error-color: #ea5455;
    --warning-text-color: #ffa129;
    --bg-scrollbar: rgba(207, 211, 236, 0.7179419608);
    --line-color: #434968;

    /* sidebar */
    --bg-sidebar: #2f3349;
    --text-color-sidebar: #b6bee3;
    --box-shadow-sidebar: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
    --bg-sidebar-backdrop: #4b465c;
    --menu-inner-shadow: linear-gradient(
        #2f3349 41%,
        rgba(47, 51, 73, 0.11) 95%,
        rgba(47, 51, 73, 0)
    );

    /* NAVBAR */
    --bg-navbar: rgba(47, 51, 73, 0.95);
    --box-shadow-navbar: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
    --bg-navbar-before: linear-gradient(
        180deg,
        rgba(37, 41, 60, 0.7) 44%,
        rgba(37, 41, 60, 0.43) 73%,
        rgba(37, 41, 60, 0)
    );
    --border-color-navbar: #222536;
    --filter-navbar-before: saturate(200%) blur(10px);
    --mask-navbar-before: linear-gradient(
        #25293c,
        #25293c 18%,
        transparent 100%
    );
    --fill-icons: #b6bee4;
    --fill-icons-hover: #e4e8ff;
    --bg-currency: #2e4b4f;
    --color-currency: #28c76f;
    --bg-sidebar-item-hover: rgba(134, 146, 208, 0.06);
    /* TABLE */
    --table-border-color: #434968;
    --color-cell: rgb(182, 190, 227);

    /* PAGINATION */
    --bg-color-pagination: rgba(47, 51, 73, 0.95);
    --bg-color-hover-pagination: rgba(47, 51, 73, 0.75);
    --text-color-pagination: var(--primary-text-color);

    /* SELECT */
    --bg-color-select: #252839;
    --border-color-select: #434968;

    /* CARD */
    --bg-color-card: #2f3349;
    --box-shadow-card: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
    --border-color-card: #222536;

    /* INPUTS */
    --bg-color-input-disabled: #2f3349;
    --bg-color-input: #252839;
    --border-color-input: #434968;
    --placeholder-color-input: #5e6692;
    --border-color-focus-input: #3d8af5;

    /* CHECKBOX */
    --bg-color-input-checkbox: #2f3349;
    --border-color-input-checkbox: #8692d0;

    /* RANGE */
    --box-shadow-range: 0 0 0 3px rgba(0, 0, 0, 0.1),
        0 5px 20px 0 rgba(0, 0, 0, 0.4);
    --dot-color-range: #c3c9e8;
    --color-line-range: rgba(255, 255, 255, 0.6);
    --disabled-color-range: #cfd3ec;

    /* TINY ITEM */
    --bg-color-tiny-item: rgba(134, 146, 208, 0.06);
    --bg-color-tiny-item-hover: rgba(134, 146, 208, 0.3);
    --border-color-tiny-item: #434968;
    --bg-color-tiny-item-failed: rgba(134, 146, 208, 0.2);
    --border-color-tiny-failed: #434968;

    /* PAYMENTS */
    --bg-color-payment-completed: #1f2e30;
    --border-color-payment-completed: #2e6837;

    /* --bg-color-payment-pending: #2b2f42;
    --border-color-payment-pending: #56575b; */

    --bg-color-payment-pending: #332a29;
    --border-color-payment-pending: #a75b10;

    --bg-color-payment-processing: #332a29;
    --border-color-payment-processing: #a75b10;

    --bg-color-payment-expired: #32222f;
    --border-color-payment-expired: #932a2f;

    /* ROUTE */
    --bg-color-route: #2f3349;
    --border-color-route: var(--border-color-navbar);
    --box-shadow-route: var(--box-shadow-navbar);
    --text-color-naming-not-support: #5e6692;

    /* TOOLTIP */
    --text-color-tooltip: #fff;
    --bg-color-tooltip: #4a5072;

    /* SWITCHER */
    --color-switch-handle: #b6bee4;
    --bg-color-switch: #3f445f;
    --color-switch-handle-active: #ff9f43;
    --bg-color-switch-active: rgba(255, 159, 67, 0.5);

    /* USER_INFO */
    --bg-color-user-info: #25293c;

    /* TABS */
    --border-color-tabs: #434968;

    /* SUB-FRAME */
    --bg-color-subframe: #2a2d3f;
    --border-color-subframe: #434968;

    /* IPHONE SWITCH */
    --bg-color-iphone-switch: #78777f;
    --knob-color-iphone-switch: #e4e8ff;
    --active-bg-color-iphone-switch: #28c750;
    --active-bg-color-iphone-switch-hover: #1ede4e;

    /* BUTTON SUCCESS */
    --border-color-success-btn: #28c76f;
    --text-color-success-btn: #28c76f;
    --bg-color-success-btn-hover: #2e3f4c;
    /* full fill */
    --border-color-success-fill-btn: #28c76f;
    --text-color-success-fill-btn: #fff;
    --bg-color-success-fill-btn: #28c76f;
    --border-color-success-fill-btn-hover: #24b364;
    --bg-color-success-fill-btn-hover: #24b364;

    /* BUTTON ERROR */
    --border-color-error-btn: #ea5455;
    --text-color-error-btn: #ea5455;
    --bg-color-error-btn-hover: #3e364a;
    /* full fill */
    --border-color-error-fill-btn: #ea5455;
    --text-color-error-fill-btn: #fff;
    --bg-color-error-fill-btn: #ea5455;
    --border-color-error-fill-btn-hover: #d34c4d;
    --bg-color-error-fill-btn-hover: #d34c4d;

    /* BUTTON WARNING */
    --border-color-warning-btn: #ff9f43;
    --text-color-warning-btn: #ff9f43;
    --bg-color-warning-btn-hover: #403c49;
    /* full fill */
    --border-color-warning-fill-btn: #ff9f43;
    --text-color-warning-fill-btn: #fff;
    --bg-color-warning-fill-btn: #ff9f43;
    --border-color-warning-fill-btn-hover: #e68f3c;
    --bg-color-warning-fill-btn-hover: #e68f3c;

    /* ALERT */
    --bg-color-alert: #1e1e2d;
    --box-shadow-alert: 0 4px 10px rgba(0, 0, 0, 0.4);

    /* REACT-TABLE */
    --border-color-rt: #434968;
    --th-bg-color-rt: #2f3349;
    --th-bg-color-hover-rt: #2a2d3f;
}

body.light {
    --bg-body: #ffffff;
    --primary-text-color: #292932;
    --secondary-text-color: #e37118;
    --error-color: #ea5455;
    --warning-text-color: #ee8500;
    --bg-scrollbar: rgba(93, 89, 108, 0.2);
    --line-color: #dbdade;

    /* sidebar */
    --bg-sidebar: #eeeeee;
    --text-color-sidebar: #6f6b7d;
    --box-shadow-sidebar: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    --bg-sidebar-backdrop: #000;
    --menu-inner-shadow: linear-gradient(
        #fff 41%,
        rgba(255, 255, 255, 0.11) 95%,
        rgba(255, 255, 255, 0)
    );

    /* NAVBAR */
    --bg-navbar: rgba(240, 240, 240, 0.95);
    --box-shadow-navbar: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    --border-color-navbar: #dbdade;
    --bg-navbar-before: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.7) 44%,
        rgba(255, 255, 255, 0.43) 73%,
        rgba(255, 255, 255, 0)
    );
    --filter-navbar-before: saturate(200%) blur(10px);
    --mask-navbar-before: linear-gradient(
        #f8f7fa,
        #f8f7fa 18%,
        transparent 100%
    );
    --fill-icons: #6f6b7d;
    --fill-icons-hover: #565263;
    --bg-currency: #dff7e9;
    --color-currency: #28c76f;

    --bg-sidebar-item-hover: #d2d2d2;

    /* TABLE */
    --table-border-color: #dbdade;
    --color-cell: #5d596c;

    /* PAGINATION */
    --bg-color-pagination: rgba(255, 255, 255, 0.95);
    --bg-color-hover-pagination: rgba(255, 255, 255, 0.75);
    --text-color-pagination: var(--primary-text-color);

    /* SELECT */
    --bg-color-select: #fefefe;
    --border-color-select: #dbdade;

    /* CARD */
    --bg-color-card: #eeeeec;
    --box-shadow-card: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    --border-color-card: #dbdade;

    /* INPUTS */
    --bg-color-input: #fefefe;
    --bg-color-input-disabled: #f7f7f5;
    --border-color-input: #dbdade;
    --placeholder-color-input: #b7b5be;
    --border-color-focus-input: #3d8af5;

    /* CHECKBOX */
    --bg-color-input-checkbox: #fff;
    --border-color-input-checkbox: #dbdade;

    /* RANGE */
    --box-shadow-range: 0 0 0 3px rgba(75, 70, 92, 0.1),
        0 5px 20px 0 rgba(75, 70, 92, 0.4);
    --dot-color-range: #c3c9e8;
    --color-line-range: rgba(75, 70, 92, 0.2);
    --disabled-color-range: #dbdade;

    /* TINY ITEM */
    --bg-color-tiny-item: #f8f8f8;
    --bg-color-tiny-item-hover: #eeeeee;
    --border-color-tiny-item: #dadada;
    --bg-color-tiny-item-failed: #e2e2e2;

    /* PAYMENTS */
    --bg-color-payment-completed: #eafbf0;
    --border-color-payment-completed: #48d486;

    /* --bg-color-payment-pending: #f6f6f6;
    --border-color-payment-pending: #a7a7a7; */
    --bg-color-payment-pending: #fff2de;
    --border-color-payment-pending: #f9ab59;

    --bg-color-payment-processing: #fff2de;
    --border-color-payment-processing: #f9ab59;

    --bg-color-payment-expired: #fef0ef;
    --border-color-payment-expired: #e25858;

    /* ROUTE */
    --bg-color-route: #f7f7f5;
    --border-color-route: var(--border-color-navbar);
    --box-shadow-route: var(--box-shadow-navbar);
    --text-color-naming-not-support: #b7b5be;

    /* TOOLTIP */
    --text-color-tooltip: #fff;
    --bg-color-tooltip: #4b4b4b;

    /* SWITCHER */
    --color-switch-handle: #f7f7f5;
    --bg-color-switch: #aaa3c1;
    --color-switch-handle-active: #ff8524;
    --bg-color-switch-active: rgba(255, 133, 36, 0.5);

    /* USER_INFO */
    --bg-color-user-info: #dbdbda;

    /* TABS */
    --border-color-tabs: #dbdade;

    /* SUB-FRAME */
    --bg-color-subframe: #dbdbda;
    --border-color-subframe: #d1d0d1;

    /* IPHONE SWITCH */
    --bg-color-iphone-switch: #aaa3c1;
    --knob-color-iphone-switch: white;

    /* BUTTON SUCCESS */
    --border-color-success-btn: #13ab57;
    --text-color-success-btn: #13ab57;
    --bg-color-success-btn-hover: #eefbf3;
    /* full fill */
    --border-color-success-fill-btn: #13ab57;
    --text-color-success-fill-btn: #fff;
    --bg-color-success-fill-btn: #13ab57;
    --border-color-success-fill-btn-hover: #34d57c;
    --bg-color-success-fill-btn-hover: #34d57c;

    /* BUTTON ERROR */
    --border-color-error-btn: #ea5455;
    --text-color-error-btn: #ea5455;
    --bg-color-error-btn-hover: #fdf1f1;
    /* full fill */
    --border-color-error-fill-btn: #ea5455;
    --text-color-error-fill-btn: #fff;
    --bg-color-error-fill-btn: #ea5455;
    --border-color-error-fill-btn-hover: #d34c4d;
    --bg-color-error-fill-btn-hover: #d34c4d;

    /* BUTTON WARNING */
    --border-color-warning-btn: #f6891f;
    --text-color-warning-btn: #f6891f;
    --bg-color-warning-btn-hover: #fff7f0;
    /* full fill */
    --border-color-warning-fill-btn: #f6891f;
    --text-color-warning-fill-btn: #fff;
    --bg-color-warning-fill-btn: #f6891f;
    --border-color-warning-fill-btn-hover: #ffa044;
    --bg-color-warning-fill-btn-hover: #ffa044;

    /* ALERT */
    --bg-color-alert: #fff;
    --box-shadow-alert: 0 4px 8px rgba(0, 0, 0, 0.1);

    /* REACT-TABLE */
    --border-color-rt: #dbdade;
    --th-bg-color-rt: #eeeeec;
    --th-bg-color-hover-rt: #d4d3d3;
}

body {
    background-color: var(--bg-body);
    color: var(--primary-text-color);
    font-family: var(--font-family);
    overflow-x: hidden;
}

body * {
    box-sizing: border-box;
}

body.mobile-sidebar-open {
    overflow: hidden;
}

.container {
    --container-padding-x: 24px;
    max-width: 1440px;
    padding: 0 var(--container-padding-x);
    margin: 0 auto;
    width: 100%;
}

@media (max-width: 1200px) {
    .container {
        width: 100vw;
    }
}

@media (max-width: 425px) {
    .container {
        --container-padding-x: 15px;
    }
}

input::placeholder {
    color: var(--placeholder-color-input) !important;
}

/* PERFECT SCROLLBAR start */

.ps__thumb-y,
.ps__rail-y {
    width: 0.125rem !important;
}
.ps__rail-y {
    right: 0.25rem !important;
    left: auto !important;
    background: none !important;
}

.ps__rail-x {
    bottom: 0.25rem !important;
    top: auto !important;
    background: none !important;
}

[dir="rtl"] .ps__rail-y {
    right: auto !important;
    left: 0.25rem !important;
}

.ps__thumb-y,
.ps__rail-y.ps--clicking > .ps__thumb-y,
.ps__thumb-x,
.ps__rail-x.ps--clicking > .ps__thumb-x {
    background: var(--bg-scrollbar) !important;
}

.ps__rail-y:hover,
.ps__rail-y:focus,
.ps__rail-y.ps--clicking,
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking > .ps__thumb-y {
    width: 0.375rem !important;
}

/* PERFECT SCROLLBAR end */

/* Checkbox */

.form-check-input {
    width: 1.2em;
    height: 1.2em;
    min-width: 1.2em;
    margin-top: 0.135em;
    vertical-align: top;
    background-color: var(--bg-color-input-checkbox);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid var(--border-color-input-checkbox);
    appearance: none;
    print-color-adjust: exact;
}
.form-check-input[type="checkbox"] {
    border-radius: 0.25em;
}
.form-check-input[type="radio"] {
    border-radius: 50%;
}
.form-check-input:active {
    filter: brightness(90%);
}
.form-check-input:focus {
    border-color: #7367f0;
    outline: 0;
    box-shadow: 0 0.125rem 0.25rem rgba(15, 20, 34, 0.4);
}
.form-check-input:checked {
    background-color: #7367f0;
    border-color: #7367f0;
}
.form-check-input:checked[type="checkbox"] {
    background-size: cover;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='13' viewBox='0 0 15 14' fill='none'%3E%3Cpath d='M3.41667 7L6.33333 9.91667L12.1667 4.08333' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.form-check-input[type="checkbox"]:indeterminate {
    background-color: #7367f0;
    border-color: #7367f0;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9H12.5' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.7;
}

.form-check-input:checked[type="checkbox"] {
    background-size: cover;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='13' viewBox='0 0 15 14' fill='none'%3E%3Cpath d='M3.41667 7L6.33333 9.91667L12.1667 4.08333' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5;
}

.form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='5' y='5' width='8' height='8' rx='4' fill='%23fff'/%3E%3C/svg%3E%0A");
}

.form-check-input[type="radio"] {
    border-radius: 50%;
}

.form-check-input[type="radio"]:not(:disabled) {
    cursor: pointer;
}

/* FORM RANGE */

.form-range {
    width: 100%;
    height: 13.6px;
    padding: 0;
    background-color: transparent;
    appearance: none;
}
.form-range:focus {
    outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
    box-shadow: var(--box-shadow-range);
}
.form-range:focus::-moz-range-thumb {
    box-shadow: var(--box-shadow-range);
    /* box-shadow: 0 0 0 3px rgba(75, 70, 92, 0.1), 0 0.31rem 1.25rem 0 rgba(75, 70, 92, 0.4); */
}
.form-range::-moz-focus-outer {
    border: 0;
}
.form-range::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    margin-top: -5px;
    background-color: var(--dot-color-range);
    /* background-color: #fff; */
    border: 0;
    border-radius: 16px;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        transition: none;
    }
}
.form-range::-webkit-slider-thumb:active {
    background-color: var(--dot-color-range);
    /* background-color: #fff; */
}
.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    color: transparent;
    cursor: pointer;
    background-color: var(--color-line-range);
    /* background-color: rgba(75, 70, 92, 0.2); */
    border-color: transparent;
    border-radius: 16px;
}
.form-range::-moz-range-thumb {
    width: 12px;
    height: 12px;
    background-color: var(--dot-color-range);
    border: 0;
    border-radius: 16px;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        transition: none;
    }
}
.form-range::-moz-range-thumb:active {
    background-color: var(--dot-color-range);
}
.form-range::-moz-range-track {
    width: 100%;
    height: 2px;
    color: transparent;
    cursor: pointer;
    background-color: var(--color-line-range);
    /* background-color: rgba(75, 70, 92, 0.2); */
    border-color: transparent;
    border-radius: 16px;
}
.form-range:disabled {
    pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
    background-color: var(--disabled-color-range);
}
.form-range:disabled::-moz-range-thumb {
    background-color: var(--disabled-color-range);
}

.custom-modal {
    --bs-modal-zindex: 1090;
    --bs-modal-width: 35rem;
    --bs-modal-padding: 1.5rem;
    --bs-modal-margin: 1.5rem;
    --bs-modal-color: ;
    --bs-modal-bg: #fff;
    --bs-modal-border-color: var(--bs-border-color-translucent);
    --bs-modal-border-width: 0px;
    --bs-modal-border-radius: 0.375rem;
    --bs-modal-box-shadow: 0 0.31rem 1.25rem 0 rgba(75, 70, 92, 0.4);
    --bs-modal-inner-border-radius: calc(0.375rem - 0px);
    --bs-modal-header-padding-x: 0.25rem;
    --bs-modal-header-padding-y: 1.25rem;
    --bs-modal-header-padding: 1.25rem 1.5rem 0.25rem;
    --bs-modal-header-border-color: var(--bs-border-color);
    --bs-modal-header-border-width: 0px;
    --bs-modal-title-line-height: 1.47;
    --bs-modal-footer-gap: 1rem;
    --bs-modal-footer-bg: ;
    --bs-modal-footer-border-color: var(--bs-border-color);
    --bs-modal-footer-border-width: 0px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    transition: opacity 0.15s linear;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.custom-modal-dialog {
    position: relative;
    width: auto;
    margin: auto;
    pointer-events: none;
    transition: transform 0.15s ease-out;
    transform: translateY(-100px) scale(0.8);
}

.custom-modal.show .custom-modal-dialog {
    transform: translateY(0) scale(1);
}

.custom-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 6px;
    outline: 0;
    max-width: 700px;
}

.custom-modal-btn-close {
    box-sizing: content-box;
    background-color: #fff;
    width: 18px;
    height: 18px;
    padding: 6px;
    color: #4b465c;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 4.5L5 13.5' stroke='%23a5a3ae' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 4.5L5 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='%23a5a3ae' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 4.5L14 13.5' stroke='white' stroke-opacity='0.2' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    opacity: 1;
    background-position: center;
    background-size: contain;
    border: 0;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
    box-shadow: 0 0.125rem 0.25rem rgba(165, 163, 174, 0.3);
    transition: all 0.23s ease 0.1s;
    position: absolute;
    position: absolute;
    top: 25px;
}

html:not([dir="rtl"]) .custom-modal .custom-modal-btn-close {
    transform: translate(23px, -25px);
}

html:not([dir="rtl"]) .custom-modal .custom-modal-btn-close {
    right: 16px;
}

html:not([dir="rtl"]) .custom-modal .custom-modal-btn-close:hover {
    transform: translate(20px, -20px);
}

/* TAB */

.tab-buttons-wrapper {
    display: flex;
    justify-content: center;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 6px;
    overflow: hidden;
}

.tab-buttons button {
    border: none;
    padding: 0;
    cursor: pointer;
    color: var(--primary-text-color);
    transition: all 0.3s;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    background-color: var(--bg-color-card);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0%;
    gap: 5px;
    min-width: 100px;
}

.tab-buttons button svg {
    width: 18px;
    height: auto;
    display: flex;
    fill: var(--fill-icons);
    margin-top: -4px;
}

.tab-buttons button.active {
    background-color: rgba(40, 199, 111, 0.6);
}

/*  */

.break-all {
    word-break: break-all;
}

/* Textarea */

.textarea-wrapper {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    border: 1px solid var(--border-color-input) !important;
    transition: border-color 0.15s ease-in-out;
}

.textarea-wrapper textarea {
    border: none !important;
    width: 100% !important;
}

.textarea-wrapper .textarea-resize {
    width: 15px;
    height: 15px;
    background: var(--bg-color-input);
    pointer-events: none;
    position: absolute;
    right: 0;
    bottom: 0;
}

.textarea-wrapper textarea::-webkit-scrollbar {
    width: 13px;
}

.textarea-wrapper textarea::-webkit-scrollbar-thumb {
    cursor: default;
    cursor: grab;

    background: linear-gradient(
        to right,
        var(--bg-color-input) 0px 9px,
        var(--bg-scrollbar) 9px 11px,
        var(--bg-color-input) 11px 14px
    );
}

.textarea-wrapper textarea::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(
        to right,
        var(--bg-color-input) 0px 7px,
        var(--bg-scrollbar) 7px 11px,
        var(--bg-color-input) 11px 14px
    );
}

.textarea-wrapper textarea::-webkit-scrollbar-thumb:active {
    cursor: grabbing;
}

.textarea-wrapper .textarea-resize svg {
    margin: 0 1px 1px 0;
}

.textarea-wrapper .textarea-resize svg path {
    stroke: var(--fill-icons);
}

/* Warning modal */
.warning-modal .modal-wnd-content {
    max-width: 400px;
}

.warning-modal-content h2 {
    text-align: center;
    margin-top: 0;
    font-size: 18px;
}

.warning-modal-content .buttons-block {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.checkbox-with-label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: 3px;
}

.checkbox-with-label label {
    cursor: pointer;
}
