.iphone-switch {
    --size: 0.67;
    position: relative;
    display: inline-block;
    width: calc(70px * var(--size));
    height: calc(40px * var(--size));
    cursor: pointer;
}

.iphone-switch input[type="checkbox"] {
    /* display: none;*/
    -webkit-appearance: none;
}
.iphone-switch input:checked + .knob {
    background-color: var(--active-bg-color-iphone-switch);
}
.iphone-switch:hover input:checked + .knob {
    background-color: var(--active-bg-color-iphone-switch-hover);
}

.iphone-switch input + .knob {
    transition: all 0.3s ease-in;
}
.iphone-switch input + .knob:before {
    transition: all 0.3s ease-in;
}

.iphone-switch input:checked + .knob:before {
    transform: translateX(calc(30.4px * var(--size)));
}

.iphone-switch .knob {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--bg-color-iphone-switch);
    border-radius: 40px;
}

.iphone-switch .knob:before {
    position: absolute;
    background-color: var(--knob-color-iphone-switch);
    content: "";
    left: calc(4px * var(--size));
    top: calc(4px * var(--size));
    width: calc(32px * var(--size));
    height: calc(32px * var(--size));
    border-radius: 50%;
}
