.accordion-item .accordion-header {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    cursor: pointer;
}

.accordion-item .accordion-header svg {
    width: 13px;
    transition: all 0.3s;
}

.accordion-item.active .accordion-header svg {
    transform: rotate(90deg);
}

.accordion-item .accordion-header svg path {
    fill: var(--fill-icons);
    transition: all 0.3s ease-in-out;
}

.accordion-item .accordion-header:hover svg path {
    fill: var(--fill-icons-hover);
}

.accordion-content-wrapper {
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.accordion-item
    .accordion-content-wrapper:not(
        .accordion-item.active .accordion-content-wrapper
    ) {
    height: 0 !important;
}

.accordion-content {
    padding: 15px 0 0;
}

.accordion-content > div {
    margin: 0 !important;
}
