.sidebar {
    background: var(--bg-sidebar);
    color: var(--text-color-sidebar);
    box-shadow: var(--box-shadow-sidebar);
    position: fixed;
    left: 0;
    top: var(--warn-height, 0px);
    height: calc((var(--vh, 1vh) * 100) - var(--warn-height, 0px));
    width: 260px;
    z-index: 40;
    transition: left 0.3s, width 0.3s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sidebar.hide {
    pointer-events: none;
}

.sidebar-backdrop {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background: var(--bg-sidebar-backdrop);
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
}

.sidebar-header {
    padding-top: 10px;
    margin: 0 0.875rem 0 1rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    position: relative;
}

.sidebar-header-content {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding-bottom: 2px;
}

.sidebar-header .mainmenu-logo {
    transition: left 0.3s;
    left: 0;
    position: relative;
}

.sidebar-header .mainmenu-logo div {
    width: 170px;
    background-size: cover;
    height: 46px;
    margin: 0;
    padding: 0;
}

.logo-backdrop {
    position: absolute;
    height: 100%;
    left: 42px;
    background: var(--bg-sidebar);
    width: 200px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s, left 0.3s;
}

@media (min-width: 1200px) {
    .menu-collapsed:not(.menu-collapsed-hover) .logo-backdrop {
        opacity: 1;
        left: 56px;
    }

    .menu-collapsed:not(.menu-collapsed-hover) .sidebar-header .mainmenu-logo {
        left: -1px;
    }
}

.sidebar-header .menu-collapse-btn {
    padding: 0;
    background: none;
    border: 0;
    position: relative;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
}

.sidebar-header .menu-collapse-btn svg {
    transition: all 0.1s ease-in-out;
    display: flex;
}

.sidebar-header .menu-collapse-btn svg path {
    fill: var(--fill-icons);
}

.sidebar-header .menu-collapse-btn:hover svg path {
    fill: var(--fill-icons-hover);
}

.sidebar-header
    .menu-collapse-btn
    svg:not(.menu-collapsed .sidebar-header .menu-collapse-btn svg) {
    transform: rotate(180deg);
}

.menu-collapsed .sidebar-header .menu-collapse-btn div {
    display: none;
}

.sidebar-header .hide-sidebar {
    background: none;
    border: none;
    padding: 5px 0 0;
    cursor: pointer;
}

.sidebar-header .hide-sidebar svg {
    fill: var(--fill-icons);
}

.sidebar-header .hide-sidebar:hover svg {
    fill: var(--fill-icons-hover);
}

.menu-inner-shadow {
    background: var(--menu-inner-shadow);
    /* display: none; */
    position: absolute;
    top: 90%;
    left: -10px;
    height: 48px;
    width: 254px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 2;
}

.menu-inner-shadow.active {
    opacity: 1;
}

.sidebar-user-info {
    display: flex;
    font-size: 16px;
    color: var(--primary-text-color);
    align-items: center;
    min-width: 236px;
    gap: 5px;
    flex-wrap: wrap;
    transition: opacity 0.3s;
    background: var(--bg-color-user-info);
    border-radius: 6px;
    padding: 6px 10px;
    margin-left: -3px;
    margin-top: 6px;
    position: relative;
    z-index: 5;
}

.sidebar-user-info span:last-child {
    font-weight: 700;
}

@media (min-width: 1200px) {
    .sidebar-header .menu-collapse-btn {
        display: block;
    }

    .sidebar-header .hide-sidebar {
        display: none;
    }

    .menu-collapsed .sidebar {
        width: var(--width-sidebar);
    }

    .menu-collapsed
        .sidebar
        .sidebar-user-info:not(
            .menu-collapsed-hover .sidebar .sidebar-user-info
        ) {
        opacity: 0;
    }

    .menu-collapsed
        .sidebar
        .menu-inner-shadow:not(
            .menu-collapsed-hover .sidebar .menu-inner-shadow
        ) {
        opacity: 0;
    }

    .menu-collapsed-hover .sidebar {
        width: 260px;
    }
}

@media (max-width: 1200px) {
    .sidebar {
        left: -260px;
    }

    .mobile-sidebar-open .sidebar {
        left: 0;
    }

    .mobile-sidebar-open .sidebar-backdrop {
        opacity: 0.5;
        pointer-events: all;
    }

    .menu-inner-shadow {
        display: none;
    }
}

/* SCROLLBAR */

.sidebar .ps__thumb-y,
.sidebar .ps__rail-y {
    width: 0.125rem !important;
}
.sidebar .ps__rail-y {
    right: 0.25rem !important;
    left: auto !important;
    background: none !important;
}
[dir="rtl"] .sidebar .ps__rail-y {
    right: auto !important;
    left: 0.25rem !important;
}

.sidebar .ps__thumb-y,
.sidebar .ps__rail-y.ps--clicking > .ps__thumb-y {
    background: var(--bg-scrollbar) !important;
}

.sidebar .ps__rail-y:hover,
.sidebar .ps__rail-y:focus,
.sidebar .ps__rail-y.ps--clicking,
.sidebar .ps__rail-y:hover > .ps__thumb-y,
.sidebar .ps__rail-y:focus > .ps__thumb-y,
.sidebar .ps__rail-y.ps--clicking > .ps__thumb-y {
    width: 0.375rem !important;
}
