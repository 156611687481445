.custom-btn {
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.135s ease-in-out;
    border: 1px solid red;
    font-size: 15px;
    font-weight: 500;
    padding: 9.6px 20px;
    border-radius: 20px;
    cursor: pointer;
    width: max-content;
    position: relative;
}

.custom-btn:disabled {
    opacity: 0.65;
    cursor: default;
}

.custom-btn.is-loading span {
    opacity: 0;
}

.custom-btn.small {
    padding: 6.5px 14px;
    font-size: 13px;
}

.custom-btn.without-rounded {
    border-radius: 6px;
}

/* SUCCESS */

.custom-btn.success {
    color: var(--text-color-success-btn);
    border-color: var(--border-color-success-btn);
}

.custom-btn:not(:disabled).success:hover {
    background-color: var(--bg-color-success-btn-hover);
}

/* full-fill */

.light .custom-btn.success.full-fill {
    color: var(--text-color-success-fill-btn);
    border-color: var(--border-color-success-fill-btn);
    background-color: var(--bg-color-success-fill-btn);
}

.light .custom-btn:not(:disabled).success.full-fill:hover {
    background-color: var(--bg-color-success-fill-btn-hover);
    border-color: var(--border-color-success-fill-btn-hover);
}

/* ERROR */

.custom-btn.error {
    color: var(--text-color-error-btn);
    border-color: var(--border-color-error-btn);
}

.custom-btn:not(:disabled).error:hover {
    background-color: var(--bg-color-error-btn-hover);
}

/* full-fill */

.custom-btn.error.full-fill {
    color: var(--text-color-error-fill-btn);
    border-color: var(--border-color-error-fill-btn);
    background-color: var(--bg-color-error-fill-btn);
}

.custom-btn:not(:disabled).error.full-fill:hover {
    background-color: var(--bg-color-error-fill-btn-hover);
    border-color: var(--border-color-error-fill-btn-hover);
}

/* WARNING */

.custom-btn.warning {
    color: var(--text-color-warning-btn);
    border-color: var(--border-color-warning-btn);
}

.custom-btn:not(:disabled).warning:hover {
    background-color: var(--bg-color-warning-btn-hover);
}

/* full-fill */

.custom-btn.warning.full-fill {
    color: var(--text-color-warning-fill-btn);
    border-color: var(--border-color-warning-fill-btn);
    background-color: var(--bg-color-warning-fill-btn);
}

.custom-btn:not(:disabled).warning.full-fill:hover {
    background-color: var(--bg-color-warning-fill-btn-hover);
    border-color: var(--border-color-warning-fill-btn-hover);
}
