.navbar-wrapper {
    position: fixed;
    top: 0;
    left: var(--width-sidebar);
    width: calc(100vw - var(--width-sidebar));
    height: 78px;
    z-index: 20;
}

.menu-collapsed .navbar-wrapper {
    transition: all 0.3s;
}

.navbar-wrapper::before {
    backdrop-filter: var(--filter-navbar-before);
    background: var(--bg-navbar-before);
    mask: var(--mask-navbar-before);
    content: "";
    width: 100%;
    height: 4.875rem;
    position: fixed;
    top: 0px;
    z-index: -1;
}

.navbar {
    background: var(--bg-navbar);
    box-shadow: var(--box-shadow-navbar);
    backdrop-filter: saturate(200%) blur(6px);
    border: 1px solid var(--border-color-navbar);

    height: 65px;
    width: 100%;
    margin-top: calc(16px + var(--warn-height, 0px));
    border-radius: 6px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
}

.navbar button:not(.navbar .link-to-deposit button) {
    background: none;
    padding: 0;
    border: none;
    display: flex;
    cursor: pointer;
}

.navbar button svg,
.navbar button svg * {
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
}

@media (min-width: 1200px) {
    .navbar .toggle-menu {
        opacity: 0;
        pointer-events: none;
    }
}

.navbar .right-block {
    display: flex;
    align-items: center;
    gap: 10px;
}

.navbar .toggle-menu svg path {
    fill: var(--fill-icons);
}

.navbar .toggle-menu:hover svg path {
    fill: var(--fill-icons-hover);
}

.navbar .toggle-theme svg g {
    stroke: var(--fill-icons);
}

.navbar .toggle-theme:hover svg g {
    stroke: var(--fill-icons-hover);
}

.navbar .toggle-theme svg:last-child {
    fill: var(--fill-icons);
}

.navbar .toggle-theme:hover svg:last-child {
    fill: var(--fill-icons-hover);
}
/* <LightModeIcon />
<DarkModeIcon /> */

.navbar .toggle-theme .darkModeIcon,
.light .navbar .toggle-theme .lightModeIcon {
    display: none;
}

.light .navbar .toggle-theme .darkModeIcon {
    display: flex;
}

.navbar .badge {
    background: var(--bg-currency);
    color: var(--color-currency);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
}

.navbar .balance {
    color: var(--primary-text-color);
    font-size: 18px;
    line-height: 1.37;
    margin: 0;
    font-weight: 600;
}

.navbar .link-to-deposit {
    text-decoration: none;
}

@media (max-width: 425px) {
    .navbar {
        padding: 0 15px;
    }

    .navbar .right-block {
        gap: 8px;
    }

    .navbar .toggle-theme svg {
        width: 20px;
        height: auto;
    }

    .navbar .badge {
        width: 30px;
        height: 30px;
    }
}
