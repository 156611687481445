
.organics {
    width: 80%;
    margin: 0 auto 20px;
}

.organics.organic-stats {
    width: 70%;
}

.organics > ul {
    border: 1px solid #d9cfcb;
    border-radius: 3px;
    padding: 0;
    min-width: 550px;
}

.organics > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
    margin: 0;
    word-wrap: break-word;
}
.organics > ul > li > div {
    padding: 6px 0;
}
.organics > ul > li:nth-child(odd) {
    background-color: #f1eee7;
}
.organics > ul > li:nth-child(even) {
    background-color: #fdf8f1;
}

.organics > ul > li.header {
    background-color: #f1e8e0;
}
.organics .header .stats {
    border-left: 1px solid #e8d6c5;
}

.organics .state {
    width: 20px;
    text-align: left;
    border-left: 1px solid #e7dbd0;
    padding-left: 10px;
    padding-top: 10px;
}
.organics .gameName {
    width: 20%;
    text-align: left;
    border-left: 1px solid #e0d3c7;
    padding-left: 10px;
}
.organics .countries {
    width: 15%;
    text-align: left;
    border-left: 1px solid #e7d8d0;
    padding-left: 10px;
    padding-top: 10px;
}
.organics .targetLink {
    width: 60%;
    text-align: left;
    border-left: 1px solid #e3d5cb;
    padding-left: 10px;
    padding-top: 10px;
}

.organics .statsTargetLink {
    width: 60%;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
}
.organics .statsCountries {
    width: 15%;
    text-align: left;
    border-left: 1px solid #e7dbcc;
    padding-left: 10px;
    padding-top: 10px;
}
.organics .stats2 {
    text-align: center;
    border-left: 1px solid #e8ded2;
    padding: 8px 10px 2px;
}
.organics .stats {
    width: 75px;
    text-align: center;
    border-left: 1px solid #e5dace;
    padding: 8px 10px 2px;
}
.organics .stats a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #3e342d;
}
.organics .stats .zero {
    color: #bab1a5;
}
button.button-stats {
    background: url(../../../public/icons/stats.png) center center no-repeat;
    width: 25px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}

.organics .section {
    align-items: flex-end;
}

/*nth-child(odd)*/

.organics .info {
    padding: 0;
    width: 16%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.organics .info .subinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px;
}

.organics .info .name {

}
.organics .info .date {
    font-size: 10px;
    color: #935b2b;
}
.organics .info .id {
    font-size: 10px;
    color: #dcc3ac;
    overflow: visible;
    width: 0;
}
.organics .info .id-only {
    color: #935b2b;
    font-size: 12px;
}

.organics > ul.info, .organics > * > ul.info {
    margin: 4px 12px;
}
.organics > ul.info > li, .organics > * > ul.info > li {
    padding: 0;
    margin: 0;
}

.organics .info .rightIcons {
    margin: 3px 0 0;
    width: 0px;
}
.organics .info .rightIcons > * {
    float: right;
}