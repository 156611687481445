/* tiny button */

.fbCab {
    display: inline-block;
    position: relative;
    background: none;
    padding: 0;
    border: none;
    margin: 0 0;
}
.fbCab > .tinyItem {
    padding: 4px 3px 3px;
}

.fbCabButton svg {
    width: 100%;
    display: flex;
    height: auto;
}

.fbCabButton svg path {
    fill: var(--fill-icons) !important;
}

.fbCabButton:hover svg path {
    fill: var(--fill-icons-hover) !important;
}

.fbCabButton {
    display: inline-block;
    position: relative;
    background: none;
    width: 12px;
    /* height: 7px; */
    padding: 0;
    border: none;
    cursor: pointer;
}
.fbCabButton > .smallIcon {
    position: relative;
    vertical-align: baseline;
    opacity: 0.5;
    left: 3px;
    top: -1px;
}
.fbCabButton:hover > .smallIcon {
    opacity: 1;
}

.failedToRemove {
    background-color: #f7e6e6 !important;
}
.failedToAdd {
    background-color: var(--bg-color-tiny-item-failed) !important;
}

.deposit-funds h2 {
    margin: 5px 0 20px 20px;
}
.deposit-funds form ul > li.field > div:first-child {
    width: 190px;
    padding-left: 0px !important;
}
.deposit-funds form ul > li.field > div:last-child {
    width: 200px;
}
.deposit-funds form ul > li.submit {
    margin-top: 20px !important;
}

.deposit-funds .request {
    padding: 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px;
    max-width: 640px;
    width: 100%;
    text-align: left;
}

.deposit-funds .request * {
    color: var(--primary-text-color);
}

.deposit-funds .request > div > div {
    margin: 6px 0px;
    line-height: 14px;
}

.bigText {
    font-size: 14px;
}


.deposit-funds .request.pending,
.deposit-funds .processing {
    background: var(--bg-color-payment-pending);
    border: var(--border-color-payment-pending) 1px solid;
}

.deposit-funds .processing {
    background: var(--bg-color-payment-processing);
    border: var(--border-color-payment-processing) 1px solid;
}

.deposit-funds .request.completed {
    background: var(--bg-color-payment-completed);
    border: var(--border-color-payment-completed) 1px solid;
}

.deposit-funds .request.expired {
    background: var(--bg-color-payment-expired);
    border: var(--border-color-payment-expired) 1px solid;
    opacity: 0.8;
}

.deposit-funds .request .field > div:first-child {
    text-align: right;
    width: 35%;
}
.deposit-funds .request .field > div:last-child {
    width: 65%;
}

.deposit-funds .request .field > div:last-child span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.deposit-funds .tokenFrame {
    width: auto;
    display: inline;
    margin: 2px 2px;
    padding: 2px 5px 2px 2px;
    font-family: "Courier New", Arial, sans-serif;
    font-size: 16px;
}
.deposit-funds .tokenFrame button {
    position: relative;
}

.deposit-funds .pending .tokenFrame {
    color: #2b2e32;
}
.deposit-funds .expired .tokenFrame {
    color: #cccccc;
}

.fb-tokens .user-select {
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.fb-tokens .user-select .select {
    max-width: 500px;
    width: 100%;
}

.fb-tokens.hide-actions .custom-btn,
.fb-tokens.hide-actions table th:last-child,
.fb-tokens.hide-actions table tr td:nth-child(4){
    display: none;
}

.fb-tokens table th:nth-child(2), .fb-tokens table tr td:nth-child(2) {
    padding-right: 0;
}

.fb-tokens table tr td:nth-child(2) {
    font-size: 11px;
}

.fb-tokens .col {
    overflow-wrap: anywhere;
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.deposit-funds form {
    max-width: none;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    border-radius: 0;
}

.deposit-funds form ul li:first-child {
    margin-top: 0;
}

.deposit-funds input:not(.select input) {
    background: var(--bg-color-input);
    border: 1px solid var(--border-color-input) !important;
    border-radius: 6px !important;
    color: var(--primary-text-color);
}


.deposit-funds .inRow .inRow {
    flex-direction: row !important;
}

.deposit-funds .move-left {
    margin-left: 32px !important;
}

.deposit-funds form ul li.field > div:first-child {
    font-size: 15px;
}

@media (max-width: 700px) {
    .deposit-funds .request > div > div {
        flex-direction: column !important;
        align-items: start !important;
    }

    .deposit-funds .request .field > div:first-child {
        width: auto;
        text-align: left;
    }

    .deposit-funds .request .field > div:last-child {
        width: 85vw;
    }

    .deposit-funds .move-left {
        margin-left: 0 !important;
    }
}

@media (max-width: 560px) {
    .deposit-funds form .field {
        flex-direction: column;
    }

    .deposit-funds form .field > div:first-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 0 10px !important;
    }

    .deposit-funds form .field > div:first-child br {
        display: none;
    }

    .deposit-funds form .field > div:last-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }
}

.deposit-funds form ul li.field > div:first-child {
    font-size: 15px;
}

@media (max-width: 560px) {
    .deposit-funds form .field {
        flex-direction: column;
    }

    .deposit-funds form .field > div:first-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 0 10px !important;
    }

    .deposit-funds form .field > div:first-child br {
        display: none;
    }

    .deposit-funds form .field > div:last-child {
        width: 100% !important;
        text-align: left !important;
        padding: 0 !important;
    }
}

.deposit-funds .copy-btn {
    background: none;
    padding: 0;
    border: 0;
    cursor: pointer;
}

.deposit-funds .copy-btn svg {
    margin-bottom: -8px;
    width: 24px;
    height: auto;
    display: flex;
}

.deposit-funds .copy-btn svg path {
    fill: var(--fill-icons);
    transition: all 0.3s;
}

.deposit-funds .copy-btn:hover svg path {
    fill: var(--fill-icons-hover);
}

.fb-tokens .col {
    overflow-wrap: anywhere;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.fb-tokens .table td button {
    background: none;
    padding: 0;
    border: none;
    width: 16px;
    cursor: pointer;
}

.fb-tokens .table td button svg {
    width: 100%;
    height: auto;
    display: flex;
}

.fb-tokens .table td button svg path {
    fill: var(--fill-icons);
}

.fb-tokens .table td button:hover svg path {
    fill: var(--fill-icons-hover);
}

/* TELEGRAM PAGE */
@media (min-width: 1200px) {
    .telegram-page .card {
        max-width: 640px;
    }
}

.card-body li:last-child {
    border: 0 !important;
}

.card-body .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 1.47;
    margin: 16px 6px 0 0;
}

.timeline {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
}

.timeline .timeline-item {
    position: relative;
    padding-left: 48;
}

html:not([dir="rtl"]) .timeline-item {
    border-left: 1px solid #dbdade;
}

.timeline .timeline-point-info {
    background-color: #00cfe8 !important;
    box-shadow: 0 0 0 3px rgba(0, 207, 232, 0.16);
}

.timeline .timeline-point-info {
    background-color: #00cfe8 !important;
    box-shadow: 0 0 0 3px rgba(0, 207, 232, 0.16);
}

.timeline .timeline-item .timeline-event {
    position: relative;
    top: -16px;
    width: 100%;
    min-height: 64px;
    background-color: #fff;
    border-radius: 6px;
    padding: 17.6px 24px 9.6px;
}

.timeline .timeline-item.timeline-item-transparent .timeline-event {
    top: -22.4px;
    background-color: transparent;
}

html:not([dir="rtl"])
    .timeline
    .timeline-item.timeline-item-transparent
    .timeline-event {
    padding-left: 0;
}

.timeline .timeline-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.timeline .timeline-header > *:first-child {
    margin-right: 8px;
}

.timeline .timeline-item {
    position: relative;
    padding-left: 48px;
}

html:not([dir="rtl"]) .timeline-item {
    border-left: 1px solid var(--line-color);
}

h6 {
    margin: 0;
    font-size: 15px;
}

.telegram-block-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
}

.avatar-block {
    display: flex;
    flex-wrap: wrap;
}

.avatar {
    position: relative;
    width: 38px;
    height: 38px;
    cursor: pointer;
    margin-right: 16px;
}

.avatar div {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-image: url("../../img/da_apps.png");
    background-position: center;
    background-size: cover;
}

.avatar-block p {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.47;
}

.text-muted {
    --bs-text-opacity: 1;
    color: #a5a3ae !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
}

.telegram-link-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.telegram-link-wrapper a {
    padding: 9.6px 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.125;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    border-radius: 6px;
    transition: all 0.2s ease-in-out;

    cursor: pointer;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    transition: all 0.135s ease-in-out;
    transform: scale(1.001);
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2;
}

.telegram-link-wrapper a svg {
    width: 16px;
    margin-right: 6px;
}

.telegram-link-wrapper a:hover {
    color: #fff !important;
    background-color: #1a91da !important;
    border-color: #1a91da !important;
}

.timeline .timeline-item .timeline-event:after {
    position: absolute;
    top: 12px;
    left: -17px;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-right: 16px solid;
    border-left: 0 solid;
    border-bottom: 16px solid transparent;
    border-left-color: #fff;
    border-right-color: #dbdade;
    z-index: -1;
    content: "";
}

.timeline .timeline-point-info {
    background-color: #00cfe8 !important;
    box-shadow: 0 0 0 0.1875rem rgba(0, 207, 232, 0.16);
}

.timeline .timeline-point-warning {
    background-color: #ff9f43 !important;
    box-shadow: 0 0 0 0.1875rem rgba(255, 159, 67, 0.16);
}

.timeline .timeline-point-success {
    background-color: #28c76f !important;
    box-shadow: 0 0 0 0.1875rem rgba(40, 199, 111, 0.16);
}

.timeline .timeline-item .timeline-point {
    position: absolute;
    left: -6px;
    top: 0;
    z-index: 2;
    display: block;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 0;
    background-color: #7367f0;
}

hr {
    margin: 1rem 0;
    color: var(--line-color);
    border: 0;
    border-top: 1px solid;
    opacity: 1;
}

.telegram-page small {
    font-size: 13px;
    margin: 6px 6px 16px 0;
    display: block;
}

.telegram-page input {
    padding: 6.7px 14px;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;
    color: #6f6b7d;
}

.telegram-page .buttons-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    margin: 16px 6px 0 0;
}

.telegram-page .buttons-group button {
    width: max-content;
}

@media (max-width: 500px) {
    .timeline .timeline-item {
        padding-left: 24px;
    }

    html:not([dir="rtl"])
        .timeline
        .timeline-item.timeline-item-transparent
        .timeline-event {
        padding-right: 0;
    }
}
