.table-data-nb {
    width: 85%;
    margin: 0 auto 20px;
    padding: 0;
}

.table-data {
    width: 85%;
    margin: 0 auto 20px;
    border: 1px solid #ded1c6;
    border-radius: 3px;
    padding: 0;
    /*grid-template-columns: repeat(auto-fill, 33.33%);
    grid-template-rows: repeat(auto-fill, 100%);*/
}
.table-data.wide {
    width: 100%;
}
.table-data.wide90 {
    width: 90%;
}
.table-data.mid-width {
    width: 75%;
}
.table-data.thin {
    width: 50%;
}
.table-data.small .header {
    font-size: 12px;
}
.table-data.small .row > .col {
    font-size: 11px;
}

.table-data header,
.table-data footer,
.row {
    display: flex; /* aligns all child elements (flex items) in a row */
}

.table-data .col {
    flex: 1; /* distributes space on the line equally among items */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table-data header,
.table-data footer {
    background-color: #eee4db;
}
.table-data header > div {
    padding: 8px 0;
}
.table-data footer > div {
    padding: 8px 0;
}

.table-data .row {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
}
.table-data .row > div {
    padding: 6px 0;
}
.table-data .row:nth-child(odd) {
    background-color: #f1eee7;
}
.table-data .row:nth-child(even) {
    background-color: #fdf8f1;
}

.table-data.compact .row {
    padding: 2px 0;
}

/* new styles table */

.table-responsive {
    position: relative;
    width: calc(100% + var(--container-padding-x) * 2);
    margin-left: calc(var(--container-padding-x) * -1);
    padding-left: var(--container-padding-x);
    padding-right: var(--container-padding-x);
    padding-bottom: 10px;
}

.table {
    caption-side: bottom;
    border-collapse: collapse;
    width: 100%;
    vertical-align: middle;
    border-color: var(--table-border-color);
}

.table thead,
.table tbody,
.table tfoot,
.table tr,
.table td,
.table th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.table th {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 14px 20px;
    color: var(--primary-text-color);
    white-space: nowrap;
    font-weight: 600;
    text-align: inherit;
    text-align: -webkit-match-parent;
    border-bottom-width: 1px;
    text-align: left;
}

.table td {
    padding: 8.8px 20px;
    border-top-width: 1px;
    box-shadow: inset 0 0 0 9999px transparent;
    font-size: 13px;
    text-align: left;
}

.table .table-footer td,
.table .table-footer th {
    font-weight: 600;
}

.table .semibold {
    font-weight: 600;
}

.table .nowrap {
    white-space: nowrap;
}
